import React from "react";
import { Modal, ModalHeader } from "reactstrap";
import { ImgWrapper } from "gatsby-image-wrapper";
import { graphql} from "gatsby";
import emailjs from "@emailjs/browser";
import config from "../config.json";
import megumethod from "../images/logo_gray.png";
import coloredMeguMethod from "../images/colored-megu.svg";
import Layout from "../components/Layout";
import NewsLatter from "../components/NewsLatter";

export default class extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: false,
			modalMessage: "",
			showStep2: false,
			appRegistration: {
				typeName: props.data.metadata.event.topic,
				typeImage: props.data.metadata.event.topic_icon,
				title: "",
				email: "",
				company: "",
				author: "",
				telephone: "",
				description: "",
				checkRules: false,
				checkPrivacyPolicy: false
			}
		};

		this.appRef = React.createRef();

		this.toggle = this.toggle.bind(this);
		this.handleCheckboxClick = this.handleCheckboxClick.bind(this);
		this.modalMessage = "";
	}

	componentDidMount() {
		document.title = this.props.data.metadata.title;
	}

	toggle() {
		this.setState({
			modal: !this.state.modal
		});
	}

	scrollToAppRef = () => {
		window.scrollTo({
			top: this.appRef.current.offsetTop,
			behavior: "smooth"
		});
	};

	handleChange(event) {
		this.setState({ value: event.target.value });
	}

	handleStep2(typeName, typeImage) {
		const { appRegistration } = this.state;
		appRegistration.typeName = typeName;
		appRegistration.typeImage = typeImage;

		this.setState({
			showStep2: true,
			appRegistration
		});
	}

	handleCloseStep2() {
		this.setState({
			showStep2: false
		});
	}

	async handleRegisterApp() {
		let errorMsg = "";
		if (!this.state.appRegistration.title) {
			errorMsg += "Vyplňte prosím název aplikace.\n\n";
		}

		if (!this.state.appRegistration.email) {
			errorMsg += "Vyplňte prosím kontaktní email.\n\n";
		} else if (
			!this.state.appRegistration.email.match(
				/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
			)
		) {
			errorMsg += "Vyplňte prosím validní email.\n\n";
		}

		if (!this.state.appRegistration.author) {
			errorMsg += "Vyplňte prosím jméno a příjmení.\n\n";
		}

		if (!this.state.appRegistration.description) {
			errorMsg += "Vyplňte prosím popis aplikace.\n\n";
		}

		if (!this.state.appRegistration.checkPrivacyPolicy) {
			errorMsg +=
				"Před odesláním prosím udělte souhlas se zpracováním osobních údajů.\n\n";
		}

		if (!this.state.appRegistration.checkRules) {
			errorMsg += "Před odesláním se prosím seznamte s pravidly AppParade.\n\n";
		}

		if (!errorMsg) {
			const data = {
				name: this.state.appRegistration.author,
				email: this.state.appRegistration.email,
				subject: "App Parade - app registration",
				message:
					`Kategorie: ${this.state.appRegistration.typeName}\n\r` +
					`Název aplikace: ${this.state.appRegistration.title}\n\r` +
					`Telefon: ${this.state.appRegistration.telephone}\n\r` +
					`Firma: ${this.state.appRegistration.company}\n\r` +
					`Popis: ${this.state.appRegistration.description}\n\r`
			};

			try {
				await emailjs.send(
					config.EmailJSServiceId,
					config.EmailJSRegistrationTemplateId,
					data,
					config.EmailJSPublicKey
				);

				this.appForm.reset();
				this.setState({
					modal: !this.state.modal,
					modalMessage: "Vaše aplikace byla zaregistrována. Děkujeme.",
					showStep2: false,
					appRegistration: {
						typeName: this.state.appRegistration.typeName,
						typeImage: this.state.appRegistration.typeImage,
						title: "",
						email: "",
						company: "",
						author: "",
						telephone: "",
						description: "",
						checkRules: false,
						checkPrivacyPolicy: false
					}
				});
			} catch (error) {
				this.setState({
					modal: !this.state.modal,
					modalMessage: error.message
				});
				throw error;
			}
		} else {
			this.setState({
				modal: !this.state.modal,
				modalMessage: errorMsg
			});
		}
	}

	handleChangeAppRegistrationValue(event, key) {
		const { appRegistration } = this.state;
		appRegistration[key] = event.target.value;
		this.setState({
			appRegistration
		});
	}

	handleCheckboxClick(key) {
		const { appRegistration } = this.state;
		appRegistration[key] = !appRegistration[key];
		this.setState({
			appRegistration
		});
	}

	render() {
		const partnersStyles = {
			marginLeft: "2em",
			marginRight: "2em",
			marginBottom: "4em"
		};

		const { cena_creative_docku } = this.props.data.metadata.event;
		const hasCreativeDock =
			cena_creative_docku &&
			cena_creative_docku.icon &&
			cena_creative_docku.app_name &&
			cena_creative_docku.company_name;
		const { googlePlayImage, appleStoreImage } = this.props.data;

		return (
			<Layout>
				<Modal
					isOpen={this.state.modal}
					toggle={this.toggle}
					className={this.props.className}
				>
					<ModalHeader toggle={this.toggle}>
						{this.state.modalMessage}
					</ModalHeader>
				</Modal>

				<hr className="without-margin-bottom" />
				<div className="heading-wrapper">
					<div className="container-heading heading-main">
						<h1>
							Přehlídka a soutěž <br />
							tuzemských mobilních aplikací
						</h1>

						<div className="heading-secondary">
							<p className="header-text-paragraph without-margin-bottom">
								pořádá softwarová agentura
							</p>
							<img
								src={coloredMeguMethod}
								className="img-fluid"
								alt="megumethod"
							/>
						</div>
					</div>

					<hr style={{ margin: 0 }} />
					<div className="container-heading ">
						<div className="row header-info ">
							<div className="col-sm-8 header-main">
								{this.props.data.metadata.event.datetime && (
									<div className="date">
										<div className="header-text-paragraph">Datum</div>
										{this.props.data.metadata.event.datetime}
										{/* <a
											target="_blank"
											rel="noreferrer"
											href="https://ti.to/megumethod/appparade-36"
											className="header-reservation-link"
										>
											Koupit lístek
										</a> */}
									</div>
								)}
								<div className="address">
									<div className="header-text-paragraph">Místo</div>
									{this.props.data.metadata.event.location}
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* <hr className="d-md-none"/> */}
				{/* <div className="container-heading d-md-none"> */}
				{/*	<div className="row header-info text-muted"> */}
				{/*		<div className="col"> */}
				{/*			{`Téma: ${this.props.data.metadata.event.topic}`} */}
				{/*		</div> */}
				{/*	</div> */}
				{/* </div> */}

				<hr className="without-margin-top" />
				<div className="container-heading">
					<h3 className="mt-1 slash">O AppParade</h3>
				</div>
				<hr />

				<div className="container">
					<div className="row">
						<div className="col font-flexa-light">
							{this.props.data.metadata.paragraphs.map((paragraph, index) => (
								<p
									className="text-lg"
									key={`${this.props.data.metadata.title}-text-${index}`}
								>
									{paragraph}
								</p>
							))}
						</div>
					</div>
				</div>

				<hr />
				<div className="container-heading" id="register-app">
					{/* BLOCK BEFORE APP PARADE KNOW APPS */}
					{this.props.data.metadata.event.phase === "1" && (
						<h3 className="slash">Přihlaste svou aplikaci do AppParade</h3>
					)}
					{/* END BLOCK BEFORE APP PARADE KNOW APPS */}
					{/* BLOCK BEFORE APP PARADE STARTS */}
					{this.props.data.metadata.event.phase === "2" && (
						<h3 className="slash">
							Děkujeme za zaslané aplikace. O té nejlepší teď rozhodnete vy
						</h3>
					)}
					{/* END BLOCK BEFORE APP PARADE STARTS */}
					{/* BLOCK AFTER APP PARADE FINISHED */}
					{this.props.data.metadata.event.phase === "3" && (
						<h3 className="slash">{`Výsledky ${this.props.data.metadata.event.title}`}</h3>
					)}
					{/* END BLOCK AFTER APP PARADE FINISHED */}
				</div>
				<hr />

				<div className="container apps" ref={this.appRef}>
					<div className="row">
						<div className="col">
							{/* BLOCK BEFORE APP PARADE KNOW APPS */}
							{this.props.data.metadata.event.phase === "1" && (
								<div className="row registration-banner">
									<div className="apps"></div>
									<div className="col-12 p-5">
										<div className="row">
											<div className="col-lg-7 col-sm-12">
												{this.props.data.metadata.event.paragraphs.map(
													paragraph => (
														<p
															key={paragraph}
															dangerouslySetInnerHTML={{ __html: paragraph }}
															className="text-md"
														></p>
													)
												)}
												<a
													className="btn btn-primary-contrast text-uppercase btn-lg"
													href="https://7efb7o65ufx.typeform.com/to/oZAngTDm"
													target="_blank"
												>
													Přihlásit aplikaci
												</a>
											</div>
										</div>
									</div>
								</div>
							)}
							{/* END BLOCK BEFORE APP PARADE KNOW APPS */}

							{/* BLOCK BEFORE APP PARADE STARTS */}
							{this.props.data.metadata.event.phase === "2" && (
								<>
									{/* <div
										className={`row ${this.state.showStep2 ? "d-none" : ""}`}
									>
										<div className="col-sm-6 pl-5 mb-5">
											<ImgWrapper
												style={{ maxWidth: "60px" }}
												data={this.props.data.metadata.event.topic_icon}
												alt={this.props.data.metadata.event.topic}
											/>
											<b className="mt-3">
												{this.props.data.metadata.event.topic}
											</b>

										</div>
									</div> */}
									<b className="mt-3">Seznam postupujících aplikací</b>
									<br />
									<div className="row">
										<div className="col-12">
											<ol className="list-of-choosed-apps">
												{this.props.data.metadata.event.applications.map(
													application => (
														<li key={application.app_name}>
															<ImgWrapper data={application.icon} style={{minWidth: 50}}/>
															<div className="list-of-choosed-apps__text">
																<div>{application.app_name}</div>
																<div>{application.company_name}</div>
															</div>
														</li>
													)
												)}
											</ol>
										</div>
									</div>
								</>
							)}
							{/* END BLOCK BEFORE APP PARADE STARTS */}

							{/* BLOCK AFTER APP PARADE FINISHED */}
							{/* <p className="text-lg">Těšit se na vás budeme zase příště. Gratulace a sláva výtězům. Čest poraženým!</p> */}
							{this.props.data.metadata.event.phase === "3" && (
								<>
									{this.props.data.metadata.event.applications[
										this.props.data.metadata.event.jury
									] && (
										<>
											<b className="mt-3">Cena Poroty</b>
											<br />

											<div className="row">
												<div className="col-12">
													<ol className="list-of-choosed-apps">
														<li>
															<ImgWrapper
																data={
																	this.props.data.metadata.event.applications[
																		this.props.data.metadata.event.jury
																	].icon
																}
																style={{minWidth: 50}}
															/>
															<div className="list-of-choosed-apps__text">
																<div>
																	{
																		this.props.data.metadata.event.applications[
																			this.props.data.metadata.event.jury
																		].app_name
																	}
																</div>
																<div>
																	{
																		this.props.data.metadata.event.applications[
																			this.props.data.metadata.event.jury
																		].company_name
																	}
																</div>
															</div>
														</li>
													</ol>
												</div>
											</div>
										</>
									)}
									<b className="mt-3">Cena Publika</b>
									<br />

									<div className="row">
										<div className="col-12">
											<ol className="list-of-choosed-apps">
												{this.props.data.metadata.event.applications.map(
													application => (
														<li>
															<ImgWrapper data={application.icon} style={{minWidth: 50}} />
															<div className="list-of-choosed-apps__text">
																<div>{application.app_name}</div>
																<div>{application.company_name}</div>
															</div>
														</li>
													)
												)}
											</ol>
										</div>
									</div>

									{hasCreativeDock && (
										<>
											<b className="mt-3">
												Cena Creative Docku o nejpřínosnější nápad
											</b>
											<br />

											<div className="row">
												<div className="col-12">
													<ol className="list-of-choosed-apps">
														<li>
															<ImgWrapper
																data={
																	this.props.data.metadata.event
																		.cena_creative_docku.icon
																}
																style={{minWidth: 50}}
															/>
															<div className="list-of-choosed-apps__text">
																<div>
																	{
																		this.props.data.metadata.event
																			.cena_creative_docku.app_name
																	}
																</div>
																<div>
																	{
																		this.props.data.metadata.event
																			.cena_creative_docku.company_name
																	}
																</div>
															</div>
														</li>
													</ol>
												</div>
											</div>
										</>
									)}
								</>
							)}
							{/* END BLOCK AFTER APP PARADE FINISHED */}
						</div>
					</div>
				</div>

				<hr />
				<div className="container-heading">
					<h3 className="slash">Co se jinde nedozvíte</h3>
				</div>
				<hr />

				<NewsLatter
					onError={error => {
						throw error;
					}}
					modalClassName={this.props.className}
				/>

				<hr />
				<div className="container-heading">
					<h3 className="slash">Partneři</h3>
				</div>
				<hr />

				<div className="container mt-5 mb-5">
					<div className="row justify-content-center align-items-center">
						{this.props.data.metadata.partners.map(partner => (
							<div
								key={partner.name}
								className="col-sm-12 col-12 col-md-3 text-center"
								style={partnersStyles}
							>
								<a
									href={partner.url_adress}
									rel="noopener noreferrer"
									target="_blank"
								>
									<ImgWrapper
										data={partner.image}
										className="img-fluid"
										alt={partner.name}
									/>
								</a>
							</div>
						))}
					</div>
				</div>
				<hr />
				<div className="container-heading">
					<h3 className="slash">Mediální partneři</h3>
				</div>
				<hr />

				<div className="container mt-5 mb-5">
					<div className="row justify-content-center align-items-center">
						{console.log("this.props: ", this.props)}
						{this.props.data.metadata.mediaPartners.map(partner => (
							<div
								key={partner.name}
								className="col-sm-12 col-12 col-md-3 text-center"
								style={partnersStyles}
							>
								<a
									href={partner.url_adress}
									rel="noopener noreferrer"
									target="_blank"
								>
									<ImgWrapper
										data={partner.image}
										className="img-fluid"
										alt={partner.name}
									/>
								</a>
							</div>
						))}
					</div>
				</div>

				<hr />
				<div className="container-heading">
					<h3 className="slash">Organizátor</h3>
				</div>
				<hr />

				<div className="container mt-5 mb-5">
					<div className="row justify-content-center">
						<div className="col-sm-4 col-8 text-center">
							<a
								href="https://www.megumethod.com/"
								rel="noopener noreferrer"
								target="_blank"
							>
								<img src={megumethod} className="img-fluid" alt="megumethod" />
							</a>
						</div>
					</div>
				</div>
			</Layout>
		);
	}
}

export const query = graphql`
	{
		googlePlayImage: file(relativePath: { eq: "download-play.png" }) {
			name
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		appleStoreImage: file(relativePath: { eq: "download-store.png" }) {
			name
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		metadata: contentJson {
			title
			heading
			paragraphs
			partners {
				name
				url_adress
				image {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
			mediaPartners {
				name
				url_adress
				image {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
			event {
				phase
				title
				paragraphs
				images {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
					}
				}
				applications {
					app_name
					company_name
					icon {
						childImageSharp {
							fixed(width: 50, height: 50) {
								...GatsbyImageSharpFixed
							}
						}
					}
				}
				cena_creative_docku {
					app_name
					company_name
					icon {
						childImageSharp {
							fixed(width: 50, height: 50) {
								...GatsbyImageSharpFixed
							}
						}
					}
				}
				jury
				datetime
				location
				topic
				topic_icon {
					childImageSharp {
						fixed(width: 60, height: 60) {
							...GatsbyImageSharpFixed
						}
					}
				}
			}
		}
	}
`;
